/*global App : true*/

define([
    "expose-loader?exposes=$!jquery",
    "underscore",
    "backbone",
    "local-router",
    "components/modal",
    "components/modal-question",
    "components/noti",
    "components/header",
    "components/cookie-noticer",
    "i18next/i18next",
    "models/user",
    "moment",
    "components/yii-js-router",
    "utils/number-formatter",
    "utils/reporter",
    "moment/es",
    "backbone.overrides",
    "datetimepicker/jquery.datetimepicker",
    // "imports?jQuery=jquery!lib/jquery.lazyload/jquery.lazyload"
], function($, _, Backbone, LocalRouter, Modal, ModalQuestion, Noti, Header, CookieNoticer, i18n, UserModel, moment, YiiJsRouter, NumberFormatter, Reporter) {
        "use strict";
        Reporter.init();
        var $body = $("body");
        App.YiiJsRouter = new YiiJsRouter();
        App.urlTo = function(path, params) {
            return App.YiiJsRouter.generateUrl(path, params);
        };
        App.urlRegexTo = function(path, params) {
            return App.YiiJsRouter.generateRegexUrl(path, params);
        };
        App.asDecimal = function(number, precision) {
            return NumberFormatter.asDecimal(number, precision);
        };
        App.parseFloat = function(number) {
            return NumberFormatter.parseFloat(number);
        };
        App.toCurrency = function(number, currency, options) {
            return NumberFormatter.toCurrency(number, currency, options);
        };
        App.parseInt = function(number) {
            return NumberFormatter.parseInt(number);
        };
        App.Header = new Header();
        App.LocalRouter = new LocalRouter();
        App.Modal = new Modal();
        App.ModalQuestion = new ModalQuestion();
        App.CookieNoticer = new CookieNoticer();
        App.Modals = {};
        App.Events = _.extend({}, Backbone.Events);
        App.User = new UserModel({
            html: $(".js-user")
        }, { parse: true });
        App.Noti = new Noti();

        Backbone.history.start({
            hashChange: false,
            pushState: true,
            root: "/",
        });

        _.each($(".js-app-flash"), function(flash) {
            var $alert = $(flash);
            App.Noti.show($alert.text(), $alert.attr("data-type"));
        });

        $body.removeClass("is-loading no-js").addClass("js-loaded");

        if ($.fn.lazyload) {
            $(".js-lazy-image").lazyload();
        }

        //i18n
        i18n.init({
            lng: App.LANGUAGE,
            resGetPath: "/__lng__/locale/__ns__",
            getAsync: false,
            fallbackLng: "es",
            useCookie: true,
            useLocalStorage: false,
            lowerCaseLng: true,
        });

        // Moment
        moment.locale(App.LANGUAGE);

        //Datetimepicker
        $.datetimepicker.setLocale(App.LANGUAGE);

        $.datetimepicker.setDateFormatter({
            parseDate: function (date, format) {
                var d = moment(date, format);
                return d.isValid() ? d.toDate() : false;
            },

            formatDate: function (date, format) {
                return moment(date).format(format);
            },
        });

        Backbone.$.ajaxSetup({
            headers: {
                "Accept-Language" : App.LANGUAGE
            }
        });

        var methodMap = {
            create: "POST",
            update: "PUT",
            delete: "DELETE",
            read:   "GET"
        };

        Backbone.sync = function(method, model, options) {
            var type = methodMap[method];

            // Default options, unless specified.
            _.defaults(options || (options = {}), {
                emulateHTTP: Backbone.emulateHTTP,
                emulateJSON: Backbone.emulateJSON
            });

            // Default JSON-request options.
            var params = {type: type, dataType: "json"};

            // Ensure that we have a URL.
            if (!options.url) {
                params.url = _.result(model, "url") || urlError();
            }

            // Ensure that we have the appropriate request data.
            if (options.data == null && model && (method === "create" || method === "update" || method === "patch")) {
                params.contentType = "application/json";
                if (options.attrs) {
                    params.data = JSON.stringify(options.attrs);
                } else if (options.noEmpty) {
                    params.data = JSON.stringify(_.omit(model.getSyncAttrs(), function(value) {
                        return (value ? false : true);
                    }));
                } else {
                    params.data = JSON.stringify(model.getSyncAttrs());
                }
            }

            // For older servers, emulate JSON by encoding the request into an HTML-form.
            if (options.emulateJSON) {
                params.contentType = "application/x-www-form-urlencoded";
                params.data = params.data ? {model: params.data} : {};
            }

            // For older servers, emulate HTTP by mimicking the HTTP method with `_method`
            // And an `X-HTTP-Method-Override` header.
            if (type === "POST" || type === "PUT" || type === "DELETE" || type === "PATCH") {
                if (options.emulateJSON) params.data._method = type;
                var beforeSend = options.beforeSend;
                options.beforeSend = function(xhr) {
                    xhr.setRequestHeader("X-CSRF-Token", App.Constants.CSRF_TOKEN);
                    if (beforeSend) return beforeSend.apply(this, arguments);
                };
            };

            // Don"t process data on a non-GET request.
            if (params.type !== "GET" && !options.emulateJSON) {
                params.processData = false;
            }

            // If we"re sending a `PATCH` request, and we"re in an old Internet Explorer
            // that still has ActiveX enabled by default, override jQuery to use that
            // for XHR instead. Remove this line when jQuery supports `PATCH` on IE8.
            if (params.type === "PATCH" && noXhrPatch) {
                params.xhr = function() {
                    return new ActiveXObject("Microsoft.XMLHTTP");
                };
            }

            // Make the request, allowing the user to override any Ajax options.
            var xhr = options.xhr = Backbone.ajax(_.extend(params, options));
            model.trigger("request", model, xhr, options);
            return xhr;
        }
    });
