define([
    "@elparking/reporter",
    "@sentry/browser",
], function(Reporter, Sentry) {
    "use strict";

    const developmentEnvironments = [
        'local',
        'test',
    ]

    var ReporterView = {
        init() {
            if (this.active()) {
                Reporter.init(
                    Reporter.ERROR_LEVEL,
                    {
                        dsn: App.Constants.SENTRY_URI,
                        environment: App.Constants.YII_ENV,
                        project: 'elParkingAdmin',
                    },
                    function (params) {
                        Sentry.init(params)
                    },
                )
            }
        },

        active () {
            return !developmentEnvironments.includes(App.Constants.YII_ENV)
        }
    };

    return ReporterView;
});

